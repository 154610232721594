import React, { useContext } from "react";
import Lottie from "lottie-react";

import successLottie from "../../assets/images/success.json";
import "./successbooking.css";
import Navbar from "../PackagesList/Navbar/navbar";
import Footer from "../footer/footer";
import { useParams } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";

const SuccessBooking = () => {
  const { userInfo } = useContext(UserContext);
  const { bookingId } = useParams();
  return (
    <>
      <Navbar />
      <div class="confirmation-container">
        <div class="confirmation-box">
          <div class="icon">
            <Lottie
              animationData={successLottie}
              loop={true}
              style={{ width: "140px", height: "140px" }}
            />
          </div>
          <h2>
            Hey, {userInfo?.name},
            <br />
            Your Booking is Confirmed!
          </h2>
          <p>
            Your booking id is{" "}
            <span style={{ fontWeight: "bold" }}>{bookingId}</span>{" "}
          </p>
          <p>
            We have sent you a confirmation email on {userInfo?.email} with
            booking details.
          </p>
          <button class="button" onClick={() => (window.location.href = "/")}>
            Back to home
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SuccessBooking;
