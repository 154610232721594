import {
  markPaymentSuccess,
  verifyOrderDetails,
} from "../../../app/components/Services/services";
import logo from "../../../app/assets/images/drawerLogo.png";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const verifyAndMarkPayment = async (
  orderCreationId,
  razorpayPaymentId,
  razorpaySignature
) => {
  const verifyOrderResponse = await verifyOrderDetails(
    orderCreationId,
    razorpayPaymentId,
    razorpaySignature
  );
  if (verifyOrderResponse.status === 200) {
    const { payment_order_id } = verifyOrderResponse.data.data;
    const succssOrderResponse = await markPaymentSuccess(payment_order_id);
    if (succssOrderResponse.status === 200) {
      const bookingId = succssOrderResponse.data.data.booking_id;
      window.location.href = `/booking/${bookingId}/success`;
    }
  }
};

export async function openRazorpayInterface(
  totalAmount,
  orderId,
  currencyCode,
  history,
  previousPageUrl,
  userInfo
) {
  const response = await loadScript(
    process.env.REACT_APP_RAZORPAY_CHECKOUT_URL
  );

  const options = {
    key: process.env.REACT_APP_RAZORPAY_KEY,
    amount: totalAmount * 100,
    currency: currencyCode,
    name: "Trip Store Holidays",
    description: "India's Biggest Group Tour Marketplace.",
    image: logo,
    order_id: orderId,
    handler: async function (response) {
      const data = {
        orderCreationId: orderId,
        razorpayPaymentId: response.razorpay_payment_id,
        razorpayOrderId: response.razorpay_order_id,
        razorpaySignature: response.razorpay_signature,
      };
      const paymentResponse = await verifyAndMarkPayment(
        data.orderCreationId,
        data.razorpayPaymentId,
        data.razorpaySignature
      );
    },
    modal: {
      ondismiss: () => {
        history.push(previousPageUrl)
      },
    },
    prefill: {
      name: userInfo.name,
      email: userInfo.email,
      contact: userInfo.phone_number,
    },
    notes: {
      address: "309, Centura Square, Rd Number 27, Wagle Industrial Estate, Thane West, Thane, Maharashtra 400604",
    },
    theme: {
      color: "#ff6840",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
