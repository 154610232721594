import React, { useContext, useEffect, useState } from "react";
import "./paymentprocess.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams,useHistory } from "react-router-dom";
import { Base64 } from "js-base64";
import { generateOrderId } from "../Services/services";
import { openRazorpayInterface } from "../../../utils/PaymentUtils/RazorPay/razorpayPayment";
import ErrorModal from "../Booking/ErrorModal";
import { UserContext } from "../hooks/UserContext";
import { NavigationContext } from "../../navigation/navigationContext";
import { getTrackingPageName } from "../../../tracking/trackingConstants";
import { useLocation } from "react-router-dom"

function PaymentProcessing() {
  const history = useHistory();
  const location = useLocation()
  const { browsingPage, setBrowsingPage } = useContext(NavigationContext);
  const previousPageUrl=sessionStorage.getItem('lastPage');
  const { userInfo } = useContext(UserContext);
  const { intentId } = useParams();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [expiryMessage, setExpiryMessage] = useState("");
  const decodedUuid = Base64.decode(intentId);

  const getOrderId = async (decodedUuid) => {
    const response = await generateOrderId(decodedUuid);
    if (response.status === 200) {
      const {
        amount,
        payment_order_id: order_id,
        currency,
      } = response.data.data;
      if (amount && order_id && currency) {
        getRazorpayInterface(amount, order_id, currency);
      }
    } else {
      setExpiryMessage(response.data.message);
      handleOpenLinkExipryModal();
    }
  };

  useEffect(() => {
    getOrderId(decodedUuid);
  }, [decodedUuid]);

  async function getRazorpayInterface(totalAmount, orderId, currencyCode) {
    const response = await openRazorpayInterface(
      totalAmount,
      orderId,
      currencyCode,
      history,
      previousPageUrl,
      userInfo
    );
  }
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };
  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    window.location.href = "/";
  };
  const handleOpenLinkExipryModal = () => {
    handleOpenErrorModal();
  };
  useEffect(()=>{	
		setBrowsingPage(getTrackingPageName(location.pathname))
	},[])
  return (
    <>
      <ErrorModal
        handleCloseErrorModal={handleCloseErrorModal}
        openErrorModal={openErrorModal}
        errorMessage={expiryMessage}
      />
      <div className="payment-process-main">
        <CircularProgress style={{ marginBottom: "10px" }} />
        <h4 style={{ margin: "0px" }}>
          Please wait while we complete your payment.
        </h4>
        <p>Please do not press close button or refresh the page.</p>
      </div>
    </>
  );
}

export default PaymentProcessing;
