import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { makeStyles } from "@material-ui/core/styles";

export const SnackbarStyle = Object.freeze({
  ERROR: "error",
  INFO: "info",
});

const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  //   success: {
  //     backgroundColor: theme.palette.success.main,
  //     color: theme.palette.success.contrastText,
  //   },
}));

const SnackbarComponent = ({
  openSnackBar,
  handleCloseSnackbar,
  handleOpenSnackBar,
  message,
  snackBarStyle = SnackbarStyle.INFO
}) => {
  const classes = useStyles();
  const snackBarClass = () => {
    switch (snackBarStyle) {
      case SnackbarStyle.ERROR:
        return classes.error;
      case SnackbarStyle.INFO:
        return classes.info;
      default:
        return classes.info;
    }
  }

  return (
    <div>
      {/* <Button variant="outlined" color="secondary" onClick={handleOpenSnackBar}>
        Show Error Snackbar
      </Button> */}
      <Snackbar
        anchorOrigin={{
          vertical: window.innerWidth <= 768 ? "top" : "bottom",
          horizontal: window.innerWidth <= 768 ? "right" : "left",
        }}
        open={openSnackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          className={snackBarClass()}
          message={message}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Snackbar>
    </div>
  );
};

export default SnackbarComponent;
