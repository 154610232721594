import React from "react";
import "./bookingcart.css";

function BookingCart({ cartPriceBreakUps, travellerCount, priceDetails }) {
  const {
    total_retail_price,
    total_sale_price,
    standard_discount,
    special_discount,
    coupon_discount,
    payable_amount,
  } = cartPriceBreakUps;

  return (
    <div class="price-details">
      <h3>
        Price Details
        <span className="traveller-info">
          ( {travellerCount} {travellerCount > 1 ? "Travellers" : "Traveller"})
        </span>
      </h3>
      { priceDetails && priceDetails.total_retail_price === priceDetails.total_sale_price ? null : (
      <p>
        Total Price{" "}
        <span>{total_retail_price ? total_retail_price : "loading..."}</span>
      </p>
      )}
       {priceDetails && priceDetails.standard_discount === 0 ? null : (
      <p>
        Early Bird Discount{" "}
        <span class="discount">
          {standard_discount ? standard_discount : "loading..."}
        </span>
      </p>
       )}
      {priceDetails && priceDetails.special_discount === 0 ? null : (
      <p>
        Sale Discount{" "}
        <span class="discount">
          {special_discount ? special_discount : "loading"}
        </span>
      </p>
      )}
      {priceDetails && priceDetails.coupon_discount === 0 ? null : (
        <p>
          Coupon Discount{" "}
          <span class="discount">
            {coupon_discount ? coupon_discount : "loading"}
          </span>
        </p>
      )}

      <p>
        Total Amount
        <span>{total_sale_price ? total_sale_price : "loading"}*</span>
      </p>
      <div class="divider"></div>
      <div class="to-be-paid">
        <span>To be Paid Now</span>
        <span>{payable_amount ? payable_amount : "loading..."}</span>
      </div>
    </div>
  );
}

export default BookingCart;
