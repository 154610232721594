import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { PrimarySubmitButton } from "../../../utils/Buttons";
import { ErrorOutlineRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
  },
}));

function ErrorModal({ handleCloseErrorModal, openErrorModal, errorMessage }) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openErrorModal}
        // onClose={handleCloseErrorModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: "black" },
          onClick: (e) => e.stopPropagation(),
        }}
      >
        <Fade in={openErrorModal}>
          <div className={classes.paper}>
            <div
              style={{
                width: "100%",
                padding: "20px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
              }}
            >
              <ErrorOutlineRounded style={{ marginRight: "5px" }} />{" "}
              {errorMessage}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimarySubmitButton onClick={handleCloseErrorModal}>
                Back to Home
              </PrimarySubmitButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ErrorModal;
