import React, { useEffect, useRef, useState } from "react";
import drawerLogo from "../../../assets/images/drawerLogo.png";
import "./loginuser.css";
import { PrimaryButton, PrimarySubmitButton } from "../../../../utils/Buttons";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import ErrorIcon from "@material-ui/icons/Error";
import ClearIcon from "@material-ui/icons/Clear";
import LoginOtp from "./LoginOtp";
import WhatsAppIcon from "../../../assets/images/whatsapp (1).png";
import { countryCodeList, loginUser } from "../../Services/services";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tracker } from "../../../../tracking/tracker";
import { loginPhoneNumberSubmitEvent } from "../../../../tracking/events/loginPhoneNumberSubmitEvent";
import { loginEndEvent } from "../../../../tracking/events/loginEndEvent";
import {
  TrackingOrigin,
  TrackingView,
} from "../../../../tracking/trackingConstants";
import { getFullUrlPath } from "../../../../utils/utility";
import { MetaLeadEvent } from "../../../../tracking/events/leadEvent";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      // height: '20px',
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInputLabel-root": {
      color: "grey",
      lineHeight: "0",
      fontSize: "1rem",
    },
  },
}));

function LoginUser({
  handleCloseLoginModal,
  // handleCloseEvent,
  setCurrentView,
  packageId,
}) {
  const location = useLocation();

  const classes = useStyles();
  const [isotpView, setIsOtpView] = useState(false);
  const [contryCodeList, setContryCodeList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [error, setError] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isCheckedWhatsapp, setIsCheckedWhatsapp] = useState(true);
  const [loadingCountryList, setLoadingCountryList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leadId, setLeadId] = useState(null);
  const fullUrlPath = getFullUrlPath(location);
  const handleLoginUser = async () => {
    // setIsOtpView(true);
    setLoading(true);
    const response = await loginUser(
      selectedCountry,
      mobileNumber,
      isCheckedWhatsapp,
      fullUrlPath,
      packageId
    );
    if (response.status >= 200 && response.status < 300) {
      if (response.data.data.access_token) {
        localStorage.setItem("access_token", response.data.data.access_token);
        setIsOtpView(false);
        window.location.reload();
        setLoading(false);
      } else {
        if (response.data.data.lead && response.data.data.lead.id) {
          setLeadId(response.data.data.lead.id);
          Tracker.getInstance().trackMetaEvent(
            new MetaLeadEvent(selectedCountry, mobileNumber)
          );
        }
        setLoading(false);
        setIsOtpView(true);
        setError("");
      }
    }
  };

  const handleChangeCheck = (event) => {
    setIsCheckedWhatsapp(event.target.checked);
  };
  const handleSubmitMobileNumber = (e) => {
    e.preventDefault();
    const mobileNumberRegex = /^[1-9]\d{8,14}$/;
    const isValidMobile = mobileNumberRegex.test(mobileNumber);

    if (isValidMobile) {
      handleLoginUser();
      Tracker.getInstance().trackEvent(new loginPhoneNumberSubmitEvent());
    } else {
      setError("Please enter number between 9 and 15 digits.");
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChangeCountryCode = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleChangeMobileNumber = (event) => {
    const { value } = event.target;

    const positiveIntegerRegex = /^\d*$/;

    if (Number(value) >= 0 && positiveIntegerRegex.test(value)) {
      setMobileNumber(value);
      setError("");
    } else {
      setError("Please enter valid number.");
    }
  };

  const getCountryCodeList = async () => {
    setLoadingCountryList(true);
    const response = await countryCodeList();

    if (response.status >= 200 && response.status < 300) {
      setLoadingCountryList(false);
      const defaultCountryCode = response.data.data.countries.filter(
        (data) => data.is_default == true
      );
      setSelectedCountry(defaultCountryCode[0]?.calling_code);

      setContryCodeList(response.data.data.countries);
    }
  };
  useEffect(() => {
    getCountryCodeList();
  }, []);

  const handleClose = () => {
    setCurrentView(TrackingView.LoginForm.PhoneNumber);
    handleCloseLoginModal();
  };
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setCurrentView(TrackingView.LoginForm.PhoneNumber);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return isotpView ? (
    <LoginOtp
      setIsOtpView={setIsOtpView}
      countryCode={selectedCountry}
      mobileNumber={mobileNumber}
      handleCloseLoginModal={handleCloseLoginModal}
      setCurrentView={setCurrentView}
      leadId={leadId}
    />
  ) : (
    <div>
      <div className="login-close-button" ref={modalRef}>
        <div className="topbar-container">
          <ClearIcon
            style={{
              padding: "5px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
        </div>

        <div class="login-header">
          <img src={drawerLogo} alt="Trip Store Logo" class="login-logo" />
        </div>
        <div style={{ flex: "4" }}></div>
      </div>
      <div class="login-container">
        <h2>Log in or sign up</h2>

        <form class="form" onSubmit={handleSubmitMobileNumber}>
          {loadingCountryList ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {" "}
              <div
                className={`select-wrapper ${
                  selectedCountry ? "has-value" : ""
                }`}
              >
                <select
                  id="country-select"
                  value={selectedCountry}
                  onChange={handleChangeCountryCode}
                  className="country-select"
                >
                  {contryCodeList?.map((country) => (
                    <option
                      key={country.calling_code}
                      value={country.calling_code}
                      className="countycode-options"
                    >
                      {country.name} (+{country.calling_code})
                    </option>
                  ))}
                </select>
                <label htmlFor="country-select" className="floating-label">
                  Country/Region
                </label>
              </div>
              <div
                className={`input-wrapper ${mobileNumber ? "has-value" : ""}`}
              >
                <div
                  className={
                    isFocused
                      ? "country-code-selected"
                      : "country-code-unselected"
                  }
                >
                  +{selectedCountry}
                </div>
                <input
                  type="number"
                  id="floating-input"
                  value={mobileNumber}
                  onChange={handleChangeMobileNumber}
                  className="floating-input"
                  required
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  min="1"
                  autoComplete="off"
                />
                <label htmlFor="floating-input" className="floating-label">
                  Phone number
                </label>
                {error && (
                  <p className="error-text">
                    <ErrorIcon
                      style={{ fontSize: "10px", marginRight: "2px" }}
                    />{" "}
                    {error}
                  </p>
                )}
              </div>
            </>
          )}

          <div className="whatsapp-text-container">
            <p className="disclaimer">
              We will send you a{" "}
              <span style={{ fontSize: "13px", fontFamily: "monospace" }}>
                6
              </span>{" "}
              digit code to verify your account.
            </p>
            <div
              style={{
                display: "flex",
                height: "0px",
                marginTop: "3px",
                marginBottom: "10px",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <input
                type="checkbox"
                checked={isCheckedWhatsapp}
                onChange={handleChangeCheck}
                style={{ padding: "0px" }}
              />{" "}
              <p className="whatsapp-update">Get updates on Whatsapp</p>{" "}
              <img
                src={WhatsAppIcon}
                alt="whatsapp-image"
                height="16px"
                width="16px"
                style={{ fontSize: "16px", marginLeft: "5px" }}
              />
            </div>
          </div>

          <PrimarySubmitButton type="submit" className="login-button">
            {loading ? (
              <CircularProgress color="inherit" size={15} />
            ) : (
              "Continue"
            )}
          </PrimarySubmitButton>
        </form>
        <p class="disclaimer" style={{ fontSize: "10px" }}>
          *By proceeding, you agree to our{" "}
          <a href="/Conditions" class="privacy-policy">
            Terms & Conditions
          </a>{" "}
          and{" "}
          <a href="/policy" class="privacy-policy">
            Privacy Policy.
          </a>
        </p>
      </div>
    </div>
  );
}

export default LoginUser;
