import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";

function CarouselComponent({ items }) {
  return (
    <div
      style={{
        borderRadius: "10px",
        overflow: "hidden",
        height: window.innerWidth <= 768 ? "180px" : "250px",
      }}
    >
      {" "}
      <Carousel
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        // selectedItem={currentIndex}
        autoPlay={true}
        interval={2000}
        infiniteLoop={true}
        transitionTime={1000}
        style={{ borderRadius: "20px" }}
      >
        {items.map((image, index) => (
          <div key={index} style={{ width: "100%", height: "auto" }}>
            <a
              href={image.redirect_path}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                src={image.image}
                alt="image"
                effect="blur"
                width="100%"
                loading="lazy"
                height="100%"
              />
            </a>
        </div>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
