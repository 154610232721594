import React from "react";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import "./bannercarousel.css";

function BannerCarousel({ data }) {
  return (
    <div className="sale-banner-container">
      <CarouselComponent items={data?.list.items} />
    </div>
  );
}

export default BannerCarousel;
